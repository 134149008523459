import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "../home/home";
import CreateApp from "../create-application";
import ValidarEmail from "../validar-email";

export default function Router() {
  return (
    <BrowserRouter>
        <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/criar" element={<CreateApp />} />
            <Route path="/validar-email" element={<ValidarEmail />} />

        </Routes>
    </BrowserRouter>
  );
}