import styles from "./styles.module.css";
import { useLocation } from "react-router-dom";
import imgFuturista from "./image1.webp";
import { useEffect, useState, useRef } from "react";
import ChamarApi from "../components/api/chamarapi";
import Loader from "../components/loader/loader";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

export default function ValidarEmail() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const [modulos, setModulos] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const selectedModulesRef = useRef(selectedModules);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);	


  useEffect(() => {
    
    const email = Cookies.get("email");
    const id = Cookies.get("id");

    const urlValidarEmail = "https://tarefas.cloud/api/aplicacao/validarEmail/";
    const metodoValidarEmail = "PUT";
    const body = { token: token, email: email, id: id };

    async function validarEmail() {
      const retorno = await ChamarApi(metodoValidarEmail, urlValidarEmail, body);
      if (retorno.status === false) {
        setError(retorno.message)
        return false;
      };
    }

    const urlObterModulos = "https://tarefas.cloud/api/modulo/obterModulos/";
    const metodoObterModulos = "GET";

    async function obterModulos() {
      const response = await ChamarApi(metodoObterModulos, urlObterModulos);
      setModulos(response.data);
    }

    async function chamaMetodos() {
      let status = await validarEmail();
      if (!status) return false;
      await obterModulos();

      setLoading(false)
    }

    chamaMetodos();

  }, [token]);

  useEffect(() => {
    selectedModulesRef.current = selectedModules; // Mantém o ref atualizado com o estado atual
  }, [selectedModules]);

  function handleCheckboxChange(event) {
    const { value, checked } = event.target;

    if (checked && selectedModulesRef.current.length >= 3) {
      event.preventDefault(); // Impede a seleção antes de mudar o estado
      toast.error("Você pode selecionar no máximo 3 módulos.");
      return; // Sai da função para evitar alterações no estado
    }

    setSelectedModules((prevSelectedModules) => {
      if (checked) {
        return [...prevSelectedModules, value];
      } else {
        return prevSelectedModules.filter((module) => module !== value);
      }
    });
  }

  async function onSubmit(event) {
    event.preventDefault();

    console.log("Módulos selecionados:", selectedModules);

    const urlSelecionarModulos = "https://tarefas.cloud/api/aplicacao/selecionarModulos/";
    const metodoSelecionarModulos = "PUT";
    const body = { token:token, modulos: selectedModules };
    
    await ChamarApi(metodoSelecionarModulos, urlSelecionarModulos, body);
    
    
  }

  return (
    <main className={styles.mainPage}>
      <section className={styles.image}>
        <img src={imgFuturista} alt="Imagem futurista" />
      </section>

      <section className={styles.formulario}>
        {!error && <>{!loading ? (
          <>
            <h1>Selecione até 3 Módulos</h1>
            <form onSubmit={onSubmit} autoComplete="off">
              <div>
                {modulos.map((modulo) => (
                  <label key={modulo.id} className={styles.checkboxWrapper}>
                    <input
                      type="checkbox"
                      id={`modulo${modulo.id}`}
                      name="modulo"
                      value={modulo.nome}
                      className={styles.hiddenCheckbox}
                      onChange={handleCheckboxChange}
                      checked={selectedModules.includes(modulo.nome)}
                    />
                    <span className={styles.checkboxLabel}>{modulo.nome}</span>
                  </label>
                ))}
              </div>

              <button type="submit">Confirmar</button>
            </form>
          </>
        ) : (
          <>
            <Loader />
            <h2>Validando E-mail...</h2>
          </>
        )}</>}

        {error && 
          <>
            <h2>Erro ao validar e-mail</h2>
            <p>{error}</p>
          </>
        }

      </section>
    </main>
  );
}
