import styles from './home.module.css';
import { Link } from 'react-router-dom';

function Home() {
    return (
        <div className={styles.homeContainer}>
            <nav className={styles.navbar}>
                <div className={styles.navbarLogo}>House App</div>
                <ul className={styles.navbarMenu}>
                    <li><a href="/#">Home</a></li>
                    <li><a href="/#features">Funcionalidades</a></li>
                    <li><a href="/#about">Sobre</a></li>
                    <li><a href="/#contact">Contato</a></li>
                </ul>
            </nav>
            <main className={styles.mainContent}>
                
                <section className={styles.introSection}>
                    <h1 className={styles.introTitle}>Simplifique suas Tarefas Domésticas com o House App</h1>
                    <p className={styles.introText}>Uma solução prática, rápida e adaptável para organizar suas tarefas de casa.</p>
                    <Link to={"/criar"} className={styles.ctaButton}>Comece Agora</Link>
                </section>
    
                <section id="features" className={styles.featuresSection}>
                    <div className={`${styles.featureCard} ${styles.featureCardSmall}`}>
                        <div className={styles.featureIcon}>💡</div>
                        <h3>Fácil de Usar</h3>
                        <p>Interface amigável para tornar a gestão das tarefas simples e intuitiva.</p>
                    </div>
                    <div className={`${styles.featureCard} ${styles.featureCardLarge}`}>
                        <div className={styles.featureIcon}>🚀</div>
                        <h3>Rápido e Eficiente</h3>
                        <p>Desempenho otimizado para ajudar você a completar as tarefas domésticas rapidamente.</p>
                    </div>
                    <div className={`${styles.featureCard} ${styles.featureCardSmall}`}>
                        <div className={styles.featureIcon}>📅</div>
                        <h3>Organização de Tarefas</h3>
                        <p>Gerencie suas listas, tarefas e afazeres com facilidade.</p>
                    </div>
                </section>
    
                <section id="about" className={styles.aboutSection}>
                    <h2 className={styles.aboutTitle}>Sobre o House App</h2>
                    <p className={styles.aboutDescription}>
                        O House App foi criado para ajudar pessoas a organizar e gerenciar suas tarefas caseiras de forma simples e eficiente,
                        trazendo mais praticidade para o dia a dia.
                    </p>
                    
                    <div className={styles.aboutCards}>
                        <div className={styles.aboutCard}>
                            <h3>Missão</h3>
                            <p>Facilitar a gestão das tarefas domésticas, proporcionando mais tempo livre para você e sua família.</p>
                        </div>
                        <div className={styles.aboutCard}>
                            <h3>Visão</h3>
                            <p>Ser o aplicativo número um para gestão de tarefas domésticas, reconhecido pela sua facilidade e eficiência.</p>
                        </div>
                        <div className={styles.aboutCard}>
                            <h3>Valores</h3>
                            <p>Compromisso com a simplicidade, acessibilidade e apoio ao usuário para melhorar o cotidiano das pessoas.</p>
                        </div>
                    </div>
                </section>
            </main>
    
            <footer id="contact" className={styles.footer}>
                <p>Contato: contato@houseapp.com</p>
                <p>Telefone: (11) 9876-5432</p>
                <p>Endereço: Rua Exemplo, 123 - São Paulo, SP</p>
            </footer>
        </div>
    );    
}

export default Home;