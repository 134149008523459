export default async function ChamarApi(metodo, url, body) {
    try {
        const response = await fetch(url, {
            method: metodo,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        const data = await response.json();

        return data;
        
    } catch (error) {
        return { status: false, message: 'Erro interno do servidor' };
    }
}