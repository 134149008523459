import styles from './styles.module.css';
import imgFuturista from './image1.webp';
import { Link } from 'react-router-dom';
import ChamarApi from '../components/api/chamarapi';
import toast from 'react-hot-toast';
import Loader from '../components/loader/loader';
import { useState } from 'react';
import emailjs from 'emailjs-com';
import Cookies from 'js-cookie';

export default function CreateApp(){

    const [loading, setLoading] = useState(false);
    const [validarEmail, setValidarEmail] = useState(false);

    async function onSubmit(event){
        event.preventDefault();
        setLoading(true);

        const nome = event.target[0].value;
        const email = event.target[2].value;
        const usuario = event.target[1].value;

        if (event.target[3].value !== event.target[4].value){
            toast.error("Senhas não conferem");
            setLoading(false);
            return;
        }

        let url = 'https://tarefas.cloud/api/aplicacao/criar-aplicacao/';
        let metodo = 'POST';
        let body = {
            nome: nome,
            usuario: usuario,
            email: email,
            senha: event.target[3].value
        }

        const status = await ChamarApi(metodo,url,body);

        if (status['status'] === false){
            toast.error(status['message']);
            setLoading(false);
            return;
        }
    
        toast.success('Aplicação criada com sucesso:');

        await emailjs.send(
            "service_visionvq",
            "template_e01fvmr",
            {
                name: usuario,
                link: "https://www.tarefas.cloud/validar-email?token="+status['token'],
                email: email,
            },
            "5u0OLj8cYZFNLqiO7"
        );

        const id = status['id'];

        Cookies.set('email', email, { expires: 1/24 })
        Cookies.set('id', id, { expires: 1/24 })

        setValidarEmail(true);
        setLoading(false);
    }

    return (
        <main className={styles.mainPage}>
            
            <aside className={styles.asideBack}>
                <Link to={"/"}>Cancelar</Link>
            </aside>

            <section className={styles.formulario}>

                <h1>Crie Sua Aplicação</h1>

                <form onSubmit={onSubmit} autocomplete="off">

                    <div>
                        <label>Dê um nome para sua aplicação</label>
                        <input type="text" placeholder="Casa Exemplo" autocomplete="nope" />
                    </div>
                    <div>
                        <label>Usuário</label>
                        <input type="text" placeholder="Usuário Exemplo" autocomplete="nope" />
                    </div>
                    <div>
                        <label>Email</label>
                        <input type="text" placeholder="exemplo@dominio.com" autocomplete="nope" />
                    </div>
                    <div>
                        <label>Senha</label>
                        <input type="password" autocomplete="nope" />
                    </div>
                    <div className={styles.ultimoCampo}>
                        <label>Confirmar senha</label>
                        <input type="password" autocomplete="nope" />
                    </div>
                    
                    {loading && <Loader />}
                    {!loading && !validarEmail && <button type="submit">Criar</button>}
                    {!loading && validarEmail && <p className={styles.ativarConta}>Verifique seu email para ativar sua conta</p>}

                </form>
            </section>
            <section className={styles.image}>
                <img src={imgFuturista} alt="Visão Futurística" />
            </section>
        </main>
    );
}